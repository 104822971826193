import React from "react";
import { NavLink } from "react-router-dom";
import "./header.css";
import { logo } from "../../assets/images";
import { MenuOutlined, UserOutlined } from "@ant-design/icons";

const Header = () => {
  return (
    <header className="header bg-black py-5 d-flex justify-content-center">
      <nav className="navbar" data-v-7b217da6="">
        <div className="navbar-inside ">
          <div className="navbar-left">
            <NavLink to="/" className="navbar-logo">
              <img src="https://xpertfirst.com/wp-content/uploads/2024/01/xpertlogo.png" width={130} alt="Company Logo" />
            </NavLink>
          </div>
          {/* <div className="navbar-right">
            <div className="navbar-items">
              <NavLink
                to="/"
                className="navbar-item"
              >English</NavLink>
              <div className="country-dropdown-wrapper" data-v-68ee5fdc="">
                <div className="navbar-item" data-v-68ee5fdc="">
                  <img
                    src="https://d222mnpmkyzhbp.cloudfront.net/prod/assets/static/ae.svg"
                    width="24"
                    height="24"
                    alt="country-flag"
                    className="country-flag"
                  />
                </div>
                <div id="country-dropdown">
                  <div className="item-right" data-v-68ee5fdc="">
                    <div
                      className="radio-circle active"
                      data-v-68ee5fdc=""
                    ></div>
                  </div>
                </div>
              </div>
              <div className="user-dropdown-wrapper" data-v-3304474e="">
                <div
                  id="open-user-dropdown-button"
                  className="navbar-item v-centered last-item active en"
                  data-v-3304474e=""
                >
                  <div
                    id="hamburger-icon-button"
                    className="hamburger-icon"
                    data-v-3304474e=""
                  >
                                    <MenuOutlined />

                     <svg
                      width="22"
                      height="20"
                      viewBox="0 0 22 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-v-3304474e=""
                    >
                      <path
                        d="M0.5 1.88405C0.5 1.21195 1.00391 0.666656 1.625 0.666656H20.375C20.9984 0.666656 21.5 1.21195 21.5 1.88405C21.5 2.55869 20.9984 3.10144 20.375 3.10144H1.625C1.00391 3.10144 0.5 2.55869 0.5 1.88405ZM0.5 9.99999C0.5 9.32535 1.00391 8.7826 1.625 8.7826H20.375C20.9984 8.7826 21.5 9.32535 21.5 9.99999C21.5 10.6746 20.9984 11.2174 20.375 11.2174H1.625C1.00391 11.2174 0.5 10.6746 0.5 9.99999ZM20.375 19.3333H1.625C1.00391 19.3333 0.5 18.7906 0.5 18.1159C0.5 17.4413 1.00391 16.8985 1.625 16.8985H20.375C20.9984 16.8985 21.5 17.4413 21.5 18.1159C21.5 18.7906 20.9984 19.3333 20.375 19.3333Z"
                        fill="black"
                        fill-opacity="0.87"
                        data-v-3304474e=""
                      ></path>
                    </svg> 
                  </div>
                  
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </nav>
    </header>
  );
};

export default Header;
