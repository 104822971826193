// websiterouting/index.js
import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router";
import { Loader } from "../../assets/images";

const Screens = lazy(() => import("../../screens"));

const WebsiteRouting = () => (
  <>
    <Suspense
      fallback={
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100vh", width: "100vw", backgroundColor: "#fff" }}
        >
          <img src={Loader} alt="loader" width={50} />
        </div>
      }
    >
      <Routes>
        <Route path="/*" element={<Screens />} />
      </Routes>
    </Suspense>
  </>
);

export default WebsiteRouting;
