import { message } from "antd";
import { useEffect, useState } from "react";

export const saveToLocalStorage = (user) => {
    // console.log(user.access_token);
    if (user?.access_token) {
      localStorage.setItem(
        'Token',
        JSON.stringify(user?.access_token)
      );
    }
  };

  export const isLogin = () => {
    if (localStorage.getItem('Token')) {
      //private agent headers set
      return true;
    }
  
    return false;
  };

  export const getToken = () => {
    const token = localStorage.getItem('Token');
    if (!token) return false;
  
    // return JSON.parse(atob(token));
    return JSON.parse(token);
  };

  export const antdToast = (msg) =>{
      message.success({
        content: msg,
        className: "custom-class",
      });
   
  };

  export const useIsMobile = (breakpoint = 768) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < breakpoint);
      };
  
      // Initial check
      handleResize();
  
      // Listen for window resize events
      window.addEventListener("resize", handleResize);
  
      // Cleanup
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [breakpoint , isMobile]); // Include breakpoint in the dependency array
  
    return isMobile;
  };

