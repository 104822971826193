import { useEffect } from "react";
import Webrouting from "./Webrouting";
import { useLocation } from "react-router";

const Routing = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return  <Webrouting /> ;
};

export default Routing;
