import React, { useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import axios from "axios";
const SignupForm = (props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [phoneNumberError, setphoneNumberError] = useState("");
  const [firstNameError, setfirstNameError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");

  const validateForm = () => {
    let isValid = true;

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }

    if (!firstName) {
      setfirstNameError("Name is required");
      isValid = false;
    } else {
      setfirstNameError("");
    }

    if (!phoneNumber) {
      setphoneNumberError("Phone Number is required");
      isValid = false;
    } else if (phoneNumber.length < 9) {
      // Minimum 9 digits validation
      setphoneNumberError("Phone Number should be at least 9 digits");
      isValid = false;
    } else {
      setphoneNumberError("");
    }

    return isValid;
  };
  const register = (data) => {
    if (validateForm()) {
      //   const user = { email };
      //   onLogin(user);
      axios
        .post("https://xpertfirst-backend.onrender.com/customers/register", {
          name: firstName,
          email: email,
          password: password,
          mobile: phoneNumber,
        })
        .then((response) => {
          // storing token in local storage
          // window.localStorage.setItem(TOKEN, response.data.payload.token);
          setSnackbarMessage("Signup successful. You can now log in!");
          setSnackbarSeverity("success");
          setOpenSnackbar(true);
          setTimeout(() => {
            props.handleClose();
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          setSnackbarMessage(error.response.request.responseText);
          setSnackbarSeverity("error");
          setOpenSnackbar(true);
        });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div>
      <form className="login-fom-con">
        <div className="form-row">
          <TextField
            variant="outlined"
            label="Full name"
            fullWidth
            margin="normal"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            error={!!firstNameError}
            helperText={firstNameError}
          />
          <TextField
            variant="outlined"
            label="Phone number"
            fullWidth
            margin="normal"
            value={phoneNumber}
            onChange={(e) => {
              const numericValue = e.target.value.replace(/\D/g, "");
              setphoneNumber(numericValue);
            }}
            error={!!phoneNumberError}
            helperText={phoneNumberError}
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
          />
        </div>
        <div className="form-row">
          <TextField
            variant="outlined"
            label="Email Address"
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            variant="outlined"
            label="Password"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={register}
          style={{
            marginTop: 20,
            backgroundColor: "#ed6329",
            marginBottom: 10,
          }}
          fullWidth
        >
          Sign Up
        </Button>
      </form>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SignupForm;
