import React, { useState } from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from 'axios';
const Login = (props) => {
  console.log("Props login are:"+JSON.stringify(props));
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const validateForm = () => {
    let isValid = true;

    // Email validation using a regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Invalid email format");
      isValid = false;
    } else {
      setEmailError("");
    }

    if (!password) {
      setPasswordError("Password is required");
      isValid = false;
    } else {
      setPasswordError("");
    }

    return isValid;
  };
  const makePayment = async (props) => {
    try {
        const stripe = await loadStripe("pk_test_51OsOmBP7zizDLNPkZMWO7mraEu8xoOpNNcy8AFPsWdWYFJTYtzBgN6GxGg8aI6WfBFoKSCRt9wIPjlPyk2uQzjqV00pKynGzAg");
        const response = await axios.post("https://xpertfirst-backend.onrender.com/payment/create-checkout-session", props);

        const session = response.data;
        console.log("Session id: ", session.id);

        // Redirect to Stripe Checkout
        await stripe.redirectToCheckout({ sessionId: session.id });
    } catch (error) {
        console.error(error);
        setSnackbarMessage('Payment failed.');
        setOpenSnackbar(true);
    }
}

  const handleLogin =async (data) => {
    if (validateForm()) {
      //   const user = { email };
      //   onLogin(user);
      axios
        .post("https://xpertfirst-backend.onrender.com/customers/login", {
          email: email,
          password: password,
        })
        .then(async (response) => {
          console.log("Email is:"+email);
          // storing token in local storage
          localStorage.setItem('TOKEN',response.data.token);
          localStorage.setItem('email',email);

          const objectString = JSON.stringify(props.servicesObject);
          localStorage.setItem('servicesObject', objectString);
   
          const paymentDetails = await makePayment({ ...props.servicesObject, CustomerEmail: email });
         

        
          console.log(
            "I am going to order"
          )
         

        
        }).catch((error) => {
            console.error(error);
            setSnackbarMessage('Login failed. Please check your credentials.');
            setOpenSnackbar(true);
          });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    // <Card style={{ maxWidth: 400, margin: "auto" }}>
    //   <CardContent>
    //     <Typography variant="h5" align="center">
    //       Login
    //     </Typography>
    <div>
        <form className="login-fom-con">
          <TextField
            label="Email Address"
            variant="outlined" 
            type="email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            label="Password"
            type="password"
            fullWidth
            variant="outlined" 
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!passwordError}
            helperText={passwordError}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogin}
            style={{ marginTop: 20,
            backgroundColor: "#ed6329"}}
            fullWidth 
          >
            Login
          </Button>
        </form>

        <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
      </div>
  );
};

export default Login;
