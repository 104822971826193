// App.js
import { Routes, Route, Router } from "react-router-dom";
import Home from "./screens/Home/Home";
import { BrowserRouter } from "react-router-dom";
import Routing from "./routing/index";
import { GoogleOAuthProvider } from "@react-oauth/google";

const App = () => {
  return (
    <>
      <BrowserRouter>
        <GoogleOAuthProvider clientId="23426298539-jdd6sf6unrt70a87dbqj6163k9ngh612.apps.googleusercontent.com">
          <Routing />
        </GoogleOAuthProvider>
        ;
      </BrowserRouter>
    </>
  );
};

export default App;
