import React, { useEffect, useRef } from "react";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";

const Map = React.memo((props) => {
  // Use useEffect to update the marker position when props.center changes
  useEffect(() => {
    // Update marker position based on the new center prop
    // You might need to adjust this logic based on your specific requirements
    setMarkerPosition(props.center);
  }, [props.center]);

  const [markerPosition, setMarkerPosition] = React.useState(props.center);

  useEffect(()=> {
    renderMarker()
  },[markerPosition])

  const renderMarker = () => (
    <>
    <Marker
      draggable={true}
      defaultAnimation={true}
      position={markerPosition || props?.center}
    //   controlPosition={markerPosition}
      onDragEnd={(event) => {
        setMarkerPosition({
          lat: event.latLng.lat(),
          lng: event.latLng.lng(),
        });
        props.onMarkerDragEnd(event);
      }}
      onClick={() => props.onMarkerClick({ name: "Your Marker" })}
    />
    </>

  );

  return (
    <GoogleMap
      defaultZoom={14}
      center={props.center}
      onClick={props.onMapClick}
      options={{
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
        fullscreenControl: false,
      }}
    >
      {markerPosition && renderMarker()}
    </GoogleMap>
  );
});

export default withGoogleMap(Map);
