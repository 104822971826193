import React from "react";
import { Input, Flex, Button } from "antd";

const CButton = ({ label, onClick, customStyles }) => {
  const defaultStyles = {
    borderRadius: 50,
    height: 40,
    backgroundColor: "#FF914D",
  };
  const mergedStyles = { ...defaultStyles, ...customStyles }; // Merge styles

  return (
    <Flex vertical gap="small" style={{ width: "100%", marginTop: 10 ,  }}>
      <Button onClick={onClick} style={mergedStyles} block type="primary">
        {label}
      </Button>
    </Flex>
  );
};

export default CButton;
