// screens/Home/index.js
import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./Home.css";
import { Input, Flex, Button } from "antd";
import Modal from "react-modal";
import GoogleMapReact from "google-map-react";
import { CButton, ImageText, Map } from "../../components";
// import Login from "../../components/login/Login.js";
import Login from "../../components/login/Login";
import SignupForm from "../../components/signup/Signup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, Col, Row, Space, Radio, Rate } from "antd";
import { renderGoogleLogin, renderGoogleLogout } from "../../helper/firebase";
import { antdToast, isLogin } from "../../helper/helper";
import Sheet from "react-modal-sheet";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CloseOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import CloseIcon from "@mui/icons-material/Close";

const { Meta } = Card;
const { TextArea } = Input;
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const responsive2 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 15,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 10,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 7,
  },
};
const responsive3 = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Home = () => {
  const amount = 35;
  const discount = 0;
  const navigate = useNavigate();

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      borderRadius: "10px",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };
  const mobileStyles = {
    content: {
      position: "fixed",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "100%",
      borderRadius: "10px",
      marginRight: "0px",
      transform: "translate(-50%, -50%)",
    },
  };
  const [isMobile, setIsMobile] = useState(false);
  const [showStickyCard, setShowStickyCard] = useState(isMobile);
  const [isOpen, setOpen] = useState(false);
  const [arrowsSlider, setarrowsSlider] = useState(true);
  const [servicesObject, setservicesObject] = useState({});

  useEffect(() => {
    localStorage.removeItem("TOKEN");
    localStorage.removeItem("servicesObject");
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setShowStickyCard(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Listen for window resize events
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [showStickyCard]);
  const timeSlots = [
    { id: 1, startTime: "09:00", endTime: "10:00" },
    { id: 2, startTime: "10:30", endTime: "11:30" },
    { id: 3, startTime: "01:00", endTime: "02:00" },
    { id: 4, startTime: "02:30", endTime: "03:30" },
    { id: 5, startTime: "04:00", endTime: "05:00" },
    { id: 6, startTime: "05:30", endTime: "06:00" },
    { id: 7, startTime: "06:30", endTime: "07:30" },
    { id: 8, startTime: "08:00", endTime: "09:00" },
  ];

  const [daysData, setDaysData] = useState([3, 4, 5, 6, 7, 8]);
  const [openSignDialog, setopenSignDialog] = React.useState(false);
  const [professionalsData, setProfessionalsData] = useState([1, 2, 3, 4]);
  const [cleaningData, setCleaningData] = useState([
    "Yes, please",
    "No, I have them",
  ]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalLocationIsOpen, setLocationIsOpen] = React.useState(false);
  const [modalLoginIsOpen, setLoginIsOpen] = React.useState(false);

  const [modalMobileIsOpen, setMobileIsOpen] = React.useState(false);
  const [modalMobileLocationIsOpen, setMobileLocationIsOpen] =
    React.useState(false);
  const [modalMobileLoginIsOpen, setMobileLoginIsOpen] = React.useState(false);

  const [isStep, setIsStep] = React.useState(1);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [errorAddress, setErrorAddress] = useState("");

  const [activeDay, setActiveDay] = useState(3);
  console.log("selectedDay :>> ", activeDay);

  const [isRadio, setIsRadio] = useState(2);
  const [activeProfession, setActiveProfession] = useState(1);
  const [activeType, setActiveType] = useState(0);
  const [materialCost, setmaterialCost] = useState(15);
  const [counters, setCounters] = useState([0, 0, 0, 0, 0]); // Initialize counters array

  const handleCounterChange = (index, newCounter) => {
    const newCounters = [...counters];
    newCounters[index] = newCounter;
    setCounters(newCounters);
  };

  const [currentLocation, setCurrentLocation] = useState({
    lat: 0,
    lng: 0,
  });
  console.log("currentLocation ", currentLocation);
  const [address, setAddress] = useState("");
  console.log("address ", address);
  var cookieValue = Cookies.get("URL");
  if (cookieValue) {
    cookieValue = cookieValue.substring(1);
  }

  // useEffect(() => {
  //   // Use the Geocoding service to convert coordinates to address

  // }, [currentLocation]);

  // const getAddress = () => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   geocoder.geocode({ location: currentLocation }, (results, status) => {
  //     if (status === "OK" && results.length > 0) {
  //       setAddress(results[0].formatted_address);
  //       // setLocationIsOpen(false)
  //       // setIsStep(2)
  //     } else {
  //       console.error("Error reverse geocoding coordinates:", status);
  //     }
  //   });
  // };

  const getLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Permission granted, call getAddress with the current location
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          getAddress(currentLocation);
        },
        (error) => {
          console.error("Error getting location:", error);
          // Handle errors or inform the user
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser");
      // Handle the case where geolocation is not supported
    }
  };

  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    minutes = Math.ceil(minutes / 30) * 30; // Round minutes to the nearest 30 minutes

    // If minutes become 60, adjust hours and reset minutes
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    // Format hours and minutes
    const formattedHours = hours < 10 ? "0" + hours : hours;
    const formattedMinutes = minutes === 0 ? "00" : minutes;

    return formattedHours + ":" + formattedMinutes;
  };
  const generateTimeSlots = (count) => {
    const currentTime = getCurrentTime();
    const timeSlots = [];

    // Parse current time
    const [currentHour, currentMinute] = currentTime.split(":").map(Number);

    // Generate time slots
    for (let i = 0; i < count; i++) {
      let newHour = currentHour + i;
      let endTime = newHour + 1;

      if (endTime === 24) endTime = 0; // Reset to 0 for midnight

      // Format hours
      newHour = newHour % 24;
      endTime = endTime % 24;

      // Format start and end times
      const startTime = `${newHour < 10 ? "0" + newHour : newHour}:${
        currentMinute === 0 ? "00" : currentMinute
      }`;
      const formattedEndTime = `${endTime < 10 ? "0" + endTime : endTime}:${
        currentMinute === 0 ? "00" : currentMinute
      }`;

      // Push time slot object
      timeSlots.push({ id: i + 1, startTime, endTime: formattedEndTime });
    }

    return timeSlots;
  };

  //const timeSlots = generateTimeSlots(8);
  const handleSignSuccessful = () => {
    setopenSignDialog(false);
    setLoginIsOpen(true);
  };
  const getAddress = (currentLocation) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: currentLocation }, (results, status) => {
      if (status === "OK" && results.length > 0) {
        setAddress(results[0].formatted_address);
        // setLocationIsOpen(false)
        // setIsStep(2)
      } else {
        console.error("Error reverse geocoding coordinates:", status);
      }
    });
  };

  const handleAddressChange = (event) => {
    if (address.trim().length > 25) {
      setErrorAddress("");
    }
    setAddress(event.target.value);
  };

  const isAddressEntered = () => {
    if (address.trim().length > 25) {
      setErrorAddress("");
      getLocationPermission();
       setLocationIsOpen(false);
       setIsStep(2);
    } else {
      setErrorAddress("Address must be at least 20 characters long.");
    }
  };

  const getCurrentLocation = () => {
    // Get current location using Geolocation API
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleMapClick = (event) => {
    console.log("Clicked on map:");
    setCurrentLocation({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    getLocationPermission();
    // Add your logic for handling map clicks here
  };

  const handleMarkerClick = (marker) => {
    console.log(`Clicked on marker: ${marker.name}`);
    // Add your logic for handling marker clicks here
  };

  const handleMarkerDragEnd = (event) => {
    console.log("Marker dragged to:", event.latLng.lat(), event.latLng.lng());
    // Update coordinates when the marker is dragged
    setCurrentLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  const openSignupDialog = () => {
    setLoginIsOpen(false);
    setopenSignDialog(true);
  };

  const handleItemClick = (identifier, index) => {
    if (identifier === "day") {
      console.log("Index is:"+index)
      setActiveDay(index);
      setmaterialCost(index * 5);
    } else if (identifier === "profession") {
      setActiveProfession(index);
    } else if (identifier === "cleaning") {
      console.log("Active type index is:" + activeType);
      if (activeType === 1) {
        setActiveType(index);
        setmaterialCost(activeDay * 5);
      } else {
        setActiveType(index);
        setmaterialCost(0);
      }
    }
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setLocationIsOpen(false);
    setLoginIsOpen(false);
  }
  function closeSignModal() {
    setopenSignDialog(false);
    setLocationIsOpen(false);
    setLoginIsOpen(false);
  }

  const renderImageCursol = () => (
    <Carousel responsive={responsive}>
      {[1, 2, 3, 4, 5].map((e, index) => (
        <ImageText
          key={index}
          counter={counters[index]}
          onCounterChange={(newCounter) =>
            handleCounterChange(index, newCounter)
          }
        />
      ))}
    </Carousel>
  );

  const handleApiLoaded = (map, maps) => {
    // Handle API loaded (optional)
  };
  const handleCardClick = (id) => {
    setSelectedCard(id);
  };

  const stepOne = () => (
    <>
      {/* <h4>Booking Details</h4> */}

      <h4>How many hours do you need your professional to stay?</h4>
      <div className="daysRow">
        {daysData.map((e, index) => (
          <h4
            onClick={() => handleItemClick("day", e)}
            key={e}
            style={{
              background: activeDay === e ? "#0097B2" : "#ffffff",
              border:
                activeDay === e ? "1px solid #0097B2" : "1px solid #000000",
              color: activeDay === e ? "#ffffff" : "#000000",
              padding: "5px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
            className={e === activeDay ? "firstDay" : ""}
          >
            {e}
          </h4>
        ))}
      </div>
      <h4>How many professionals do you need?</h4>
      <div className="daysRow">
        {professionalsData.map((e, index) => (
          <h4
            onClick={() => handleItemClick("profession", e)}
            key={e}
            style={{
              background: activeProfession === e ? "#0097B2" : "#ffffff",
              border:
                activeProfession === e
                  ? "1px solid #0097B2"
                  : "1px solid #000000",
              color: activeProfession === e ? "#ffffff" : "#000000",
              padding: "5px",
              cursor: "pointer",
              marginBottom: "5px",
            }}
            className={e === activeProfession ? "firstDay" : ""}
          >
            {e}
          </h4>
        ))}
      </div>
      <h4>Need cleaning materials?</h4>
      <div className="daysRow">
        {cleaningData.map((e, index) => (
          <h4
            onClick={() => handleItemClick("cleaning", index)}
            key={e}
            style={{
              background: activeType === index ? "#0097B2" : "#ffffff",
              border:
                activeType === index
                  ? "1px solid #0097B2"
                  : "1px solid #000000",
              color: activeType === index ? "#ffffff" : "#000000",
              padding: "5px",
              cursor: "pointer",
              marginBottom: "5px",
              width: isMobile ? "70%" : "30%",
              borderRadius: 20,
            }}
            className={index === activeProfession ? "firstDay" : ""}
          >
            {e}
          </h4>
        ))}

        {/* Rest of your content for the first div */}
      </div>
      <h4>Any instructions or special requirements?</h4>
      <TextArea
        rows={4}
        placeholder="key under the mat, ironing , window cleaning"
        maxLength={150}
      />
    </>
  );

  const stepThree = () => {
    const data = [
      {
        id: 1,
        title: "Imelda",
        description: "Description 1",
        backgroundColor: "black",
      },
      {
        id: 2,
        title: "Bhagya",
        description: "Description 2",
        backgroundColor: "red",
      },
      {
        id: 3,
        title: "Bhagya",
        description: "Description 3",
        backgroundColor: "green",
      },
      // Add more dummy data as needed
    ];

    // Function to generate an array of numbers representing days of the month
    const generateDaysArray = (numberOfDays) => {
      return Array.from({ length: numberOfDays }, (_, index) => index + 1);
    };

    const getDayName = (year, month, day) => {
      const date = new Date(year, month, day);
      const options = { weekday: "short" };
      return new Intl.DateTimeFormat("en-US", options).format(date);
    };
    // Example: Get an array of numbers representing days in a month
    const daysInMonth = generateDaysArray(30);

    const currentDate = new Date();
    const currentDay = currentDate.getDate();

    const daysToShow = daysInMonth.filter((day) => day >= currentDay);

    const handleItemClick = (type, value) => {
      console.log(`Clicked on ${type}: ${value}`);
      setSelectedDay(value);
      // Add your logic for handling item clicks here
    };

    // Function to handle time slot click
    const handleTimeSlotClick = (id) => {
      setActiveDate(id);
      console.log(`Clicked on time slot with ID: ${id}`);
      // Add your logic for handling time slot clicks here
    };
    return (
      <>
        {/* <Card
          title={"Frequency"}
          style={{
            marginBottom: 10,
            height: 58,
            backgroundColor: "rgba(0,195,255,0.2)",
          }}
          extra={
            <h4
              onClick={() =>
                isMobile ? setMobileIsOpen(true) : setIsOpen(true)
              }
              style={{
                color: "#00c3ff",
                fontSize: 15,
                textDecoration: "underline",
              }}
            >
              Change
            </h4>
          }
        /> */}
        {/* 
        <h4>Which professional do you prefer?</h4>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            marginTop: 10,
          }}
        >
          {data.map((item) => (
            <Card
              key={item.id}
              hoverable
              onClick={() => handleCardClick(item.id)}
              style={{
                padding: 10,
                width: 160,
                height: 200,
                backgroundColor:
                  selectedCard === item.id ? "rgba(0,195,255,0.2)" : "",

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
              cover={
                <img
                  style={{
                    width: 70,
                    height: 70,
                    borderRadius: "50%",
                    alignSelf: "center",
                  }}
                  alt="example"
                  src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png"
                />
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 5,
                }}
              >
                <Rate allowHalf defaultValue={2.5} style={{ fontSize: 12 }} />
              </div>
              <Meta
                title={
                  <div style={{ marginBottom: 0, textAlign: "center" }}>
                    {item.title}
                  </div>
                }
                description={
                  <div style={{ marginBottom: 0 }}>{item.description}</div>
                }
              />
            </Card>
          ))}
        </div> */}
        <h4>When would you like your service?</h4>
        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
            overflowX: 'hidden',
            scrollbarWidth: "thin", // Firefox
            WebkitScrollbarWidth: "thin",
          }}
        >
          {daysToShow.map((day) => (
            <div className="daysRowDate">
              <h3
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,.6)",
                  textAlign: "center",
                }}
              >
                {getDayName(2023, 11, day)}
              </h3>
              <h4
                key={day}
                onClick={() => handleItemClick("day", day)}
                style={{
                  background:
                    selectedDay === day ? "rgba(0,195,255,0.2)" : "#ffffff",
                  border:
                    selectedDay === day
                      ? "1px solid #00c3ff"
                      : "1px solid #000000",
                  color: selectedDay === day ? "#00c3ff" : "#1a3d51",
                  // padding: "5px",
                  cursor: "pointer",
                  // marginBottom: "5px",
                }}
              >
                {day}
              </h4>
            </div>
          ))}
        </div> */}
        <Carousel arrows={arrowsSlider} responsive={responsive2}>
          {daysToShow.map((day) => (
            <div className="daysRowDate" key={day}>
              <h3
                style={{
                  fontSize: 12,
                  color: "rgba(0,0,0,.6)",
                  textAlign: "center",
                }}
              >
                {getDayName(
                  new Date().getFullYear(),
                  new Date().getMonth(),
                  day
                )}
              </h3>
              <h4
                onClick={() => handleItemClick("day", day)}
                style={{
                  background: selectedDay === day ? "#0097B2" : "#ffffff",
                  border:
                    selectedDay === day
                      ? "1px solid #0097B2"
                      : "1px solid #000000",
                  color: selectedDay === day ? "#fff" : "#1a3d51",
                  cursor: "pointer",
                }}
              >
                {day}
              </h4>
            </div>
          ))}
        </Carousel>

        <Carousel arrows={arrowsSlider} responsive={responsive3}>
          {timeSlots.map((slot) => (
            <div className="daysRowDate" key={slot?.id}>
              <h4
                onClick={() => handleTimeSlotClick(slot?.id)}
                style={{
                  background: slot?.id === activeDate ? "#0097B2" : "#ffffff",
                  border:
                    slot?.id === activeDate
                      ? "1px solid #0097B2"
                      : "1px solid #000000",
                  color: slot?.id === activeDate ? "#fff" : "#1a3d51",
                  cursor: "pointer",
                  width: 120,
                  borderRadius: 40,
                  fontSize: 12,
                  marginTop: 20,
                }}
              >
                {slot?.startTime} - {slot.endTime}
              </h4>
            </div>
          ))}
        </Carousel>

        {/* <div
          style={{
            display: "flex",
            flexDirection: "row",
            overflow: "auto",
            scrollbarWidth: "thin", // Firefox
            WebkitScrollbarWidth: "thin",
            marginTop: 20,
          }}
        >
          {timeSlots.map((slot) => (
            <div className="daysRowDate">
              <h4
                key={slot?.id}
                onClick={() => handleTimeSlotClick(slot?.id)}
                style={{
                  background:
                    slot?.id === activeDate ? "rgba(0,195,255,0.2)" : "#ffffff",
                  border:
                    slot?.id === activeDate
                      ? "1px solid #00c3ff"
                      : "1px solid #000000",
                  color: slot?.id === activeDate ? "#00c3ff" : "#1a3d51",
                  // padding: "5px",
                  cursor: "pointer",
                  width: 120,
                  borderRadius: 40,
                  fontSize: 12,
                  // marginBottom: "5px",
                }}
              >
                {slot?.startTime} - {slot.endTime}
              </h4>
            </div>
          ))}
        </div>  */}
      </>
    );
  };

  const StickyCard = ({ onCancel, onNext, totalValue }) => (
    <Card
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        // padding: "20px",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 1,
        padding: "0px",
        margin: "0px",
        height: "15%",
        height: "80px",
        backgroundColor: "#fff",
        padding: "0",
        margin: "0",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0px", flex: 1 }}>
          <span style={{ fontSize: "15px" }}>Total:</span>
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              marginBottom: "20px",
            }}
          >
            {totalValue}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <CButton
            customStyles={{ width: "90%", height: 50, marginTop: -20 }}
            onClick={handleSelect}
            label={"Next"}
          />
        </div>
      </div>
    </Card>
  );

  const handleSelect = () => {
    setShowStickyCard(true);
    if (isStep === 1) {
      // !isMobile ? setLocationIsOpen(true) : setMobileLocationIsOpen(true);
      setLocationIsOpen(true);
      getCurrentLocation();
      // setIsStep(2);
    }
    // else if (isStep === 2) {
    //   setIsStep(3);
    // }
    else if (isStep === 2) {
      console.log("isLogin :>> ", isLogin());

      const orderDetails = {
        address: address,
        duration: `${activeDay} hours`,
        date: `${selectedDay}- Dec - 2023`,

        startTime: timeSlots[activeDate - 1]?.startTime,
        endTime: timeSlots[activeDate - 1]?.endTime,
        numProfessionals: activeProfession,
        material: activeType === 1 ? "No" : "Yes",
        counters: counters,
        frequency: "One Time",
        // service: cookieValue,
        service: "Home Cleaning",
        subtotal:
          amount *
          activeDay *
          activeProfession *
          (counters[0] > 0 ? counters[0] * 0.25 : 1),
        discount: discount,
        total:
          amount *
            activeDay *
            activeProfession *
            (counters[0] > 0 ? counters[0] * 0.25 : 1) -
          discount,
        //  subToal:
        //  amount *
        //    activeDay *
        //    activeProfession *
        //    (counters[0] > 0 ? counters[0] * 0.25 : 1),
        //    discount:discount
      };
      setservicesObject(orderDetails);
      console.log("Orders are obj:" + servicesObject);
      if (!isLogin() && selectedDay) {
        console.log("Active day is:" + selectedDay);
        // isMobile ? setMobileLoginIsOpen(true) : setLoginIsOpen(true),
        setLoginIsOpen(true);
        setarrowsSlider(false);
      }
      // else {
      //   const serviceDetails = {
      //     address: address,
      //     duration: `${activeDay} hours`,
      //     date: `${selectedDay}- Dec - 2023`,

      //     startTime: timeSlots[activeDate - 1]?.startTime,
      //     endTime: timeSlots[activeDate - 1]?.endTime,
      //     numProfessionals: activeProfession,
      //     material: activeType === 1 ? "No" : "Yes",
      //     counters: counters,
      //     frequency: "One Time",
      //     service: cookieValue,
      //     subtotal:
      //       amount *
      //       activeDay *
      //       activeProfession *
      //       (counters[0] > 0 ? counters[0] * 0.25 : 1),
      //     discount: discount,
      //     total:
      //       amount *
      //         activeDay *
      //         activeProfession *
      //         (counters[0] > 0 ? counters[0] * 0.25 : 1) -
      //       discount,

      //      subToal:
      //      amount *
      //        activeDay *
      //        activeProfession *
      //        (counters[0] > 0 ? counters[0] * 0.25 : 1),
      //        discount:discount
      //   };
      //   setservicesObject(serviceDetails);
      //   console.log("Service obj:" + servicesObject);
      //   navigate("/Summary", { state: { serviceDetails } });
      // }
    }
  };
  const handleClocl = (res) => {
    if (res) {
      setLoginIsOpen(false);
      setarrowsSlider(true);
      setMobileLoginIsOpen(false);
    }
    console.log("res :>>res :>>res :>> ", res);
  };

  const handleSheetClose = () => {
    setMobileIsOpen(false);
    setMobileLocationIsOpen(false);
    setMobileLoginIsOpen(false);
  };
  return (
    <div style={{ backgroundColor: "#fafafa" }}>
      <Header />

      <div
        style={{
          display: "flex",
          height: "100%",
          width: "70%",

          flexDirection: isMobile ? "column" : "row",
          marginBottom: showStickyCard ? "30%" : 0,
          justifyContent: "center",
          margin: "10px auto",
        }}
      >
        <div
          className="main-card"
          style={{
            width: isMobile ? "109%" : "60%",

            // width: "60%",
            height: "100%",
            marginRight: "5px",
            padding: "10px",

            backgroundColor: "#fff",
          }}
        >
          {/* {isStep === 1
            ? stepOne()
            : isStep === 2
            ? renderImageCursol()
            : stepThree()} */}

          {isStep === 1 ? stepOne() : isStep === 2 ? stepThree() : ""}

          {!showStickyCard && (
            <CButton label="Next" onClick={() => handleSelect()} />
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: isMobile ? "100%" : "40%",
            marginTop: isMobile ? 20 : 0,
          }}
        >
          <div
            className="main-card"
            style={{
              marginBottom: "10px",
              padding: 20,
              flexDirection: "column",
            }}
          >
            <h4>Booking Details</h4>
            {address && (
              <div
                className="line"
                style={{
                  height: isMobile ? "80px" : "25%",
                  maxHeight: isMobile ? "100px" : "25%",
                }}
              >
                Address{" "}
                <span
                  style={{ width: "65%", textAlign: "right" }}
                  className="clean"
                >
                  {address}
                </span>
              </div>
            )}
            {/* <div className="line">
              Frequency <span className="clean">Every weeks</span>
            </div> */}
            <div className="line">
              Service <span className="clean">Home Cleaning</span> 
              {/* <span className="clean">{cookieValue}</span> */}
            </div>
            {counters?.[0] > 0 && (
              <div className="line">
                Service Details
                <span className="clean">{counters[0]}x Cupboard Cleaning </span>
              </div>
            )}

            <div className="line">
              Duration <span className="clean">{activeDay} hours</span>
            </div>
            <div className="line">
              Number of Professionals{" "}
              <span className="clean">{activeProfession}</span>
            </div>

            {selectedDay && (
              <div className="line1" style={{ fontSize: 13, height: 40 }}>
                Date & Start Time
                <span style={{ fontSize: 13 }} className="clean">
                  {`${selectedDay} - ${
                    new Date().getMonth() + 1
                  } - ${new Date().getFullYear()}`}
                  <br />
                  {timeSlots[activeDate - 1]?.startTime} -
                  {timeSlots[activeDate - 1]?.endTime}
                </span>
              </div>
            )}

            <div className="line" style={{ marginBottom: 40 }}>
              Material{" "}
              <span className="clean">{activeType === 1 ? "No" : "Yes"}</span>
            </div>
          </div>

          {/* Third div */}
          <div className="main-card1" style={{ height: "40%", padding: 20 }}>
            <h4>Payment</h4>
            {/* <div className="line">
              Subtotal:{" "}
              <span className="clean">
                AED{" "}
                {amount *
                  activeDay *
                  activeProfession *
                  (counters[0] > 0 ? counters[0] * 0.25 : 1) +
                  materialCost}
                .00
              </span>
            </div> */}
            {/* <div className="line">
              BIWEEKLY: <span className="clean">-AED {discount}.00</span>
            </div> */}
            <div className="line">
              Total:{" "}
              <span className="clean">
                AED{" "}
                {amount * activeDay * activeProfession -
                  discount +
                  materialCost}
                .00
              </span>
            </div>
            {/* Rest of your content for the third div */}
          </div>
        </div>
      </div>
      {showStickyCard && (
        <StickyCard
          totalValue={`AED ${
            amount * activeDay * activeProfession - discount + materialCost
          }.00`}
          onNext={handleSelect}
        />
      )}

      <Modal
        isOpen={modalIsOpen}
        // onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        // style={{position:"absoulete"}}
        // style={{ ...customStyles, ...(isMobile && mobileStyles) }}
        contentLabel="Example Modal"
      >
        <div style={{ width: "60vh", alignItems: "center" }}>
          <Space direction="vertical" size={5} style={{ marginBottom: 5 }}>
            {["Weekly", "Every 2 Weeks", "One Time"].map((e, index) => (
              <Card
                title={e}
                onClick={() => setIsRadio(index)}
                size="20px"
                extra={<Radio checked={index === isRadio} />}
                className="modalCard"
                style={{
                  width: "120%",
                  height: 120,
                  marginBottom: 10,
                  borderColor: index !== isRadio ? "#000000" : "#00c3ff",
                  backgroundColor:
                    index !== isRadio ? "#ffffff" : "rgba(0,195,255,0.2)",
                }}
              >
                <ul style={{ marginTop: -10, padding: 0 }}>
                  <li>The same professional will join you every week.</li>
                  <li>The same professional will join you every week.</li>
                </ul>
              </Card>
            ))}
          </Space>
          <CButton label={"Select"} onClick={() => setIsOpen(false)} />
        </div>
      </Modal>
      <Modal
        isOpen={modalLoginIsOpen}
        // onAfterOpen={modalLocationIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        style={{ ...customStyles, ...(isMobile && mobileStyles) }}
        contentLabel="Example Modal"
      >
        <div style={{ height: "100%", width: "100%" }}>
          {/* {renderGoogleLogin(handleClocl)} */}
          <Login servicesObject={servicesObject} />
        </div>
        <div
          onClick={openSignupDialog}
          style={{
            marginTop: "30px",
            cursor: "pointer",
          }}
        >
          <a>Don't have an account? Create one</a>
        </div>
      </Modal>

      <Modal
        isOpen={openSignDialog}
        // onAfterOpen={modalLocationIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        style={{ ...customStyles, ...(isMobile && mobileStyles) }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CloseIcon onClick={closeSignModal} style={{ cursor: "pointer" }} />
        </div>
        <div style={{ height: "100%", width: "100%" }}>
          {/* {renderGoogleLogin(handleClocl)} */}
          <SignupForm handleClose={handleSignSuccessful} />
        </div>
      </Modal>
      <Modal
        isOpen={modalLocationIsOpen}
        // onAfterOpen={modalLocationIsOpen}
        onRequestClose={closeModal}
        // style={customStyles}
        style={{ ...customStyles, ...(isMobile && mobileStyles) }}
        contentLabel="Example Modal"
      >
        {/* <div style={{ height: "70vh", width: "60vh" }}>
          <Map
            containerElement={<div style={{ height: "65vh" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            center={currentLocation}
            onMapClick={handleMapClick}
            onMarkerDragEnd={handleMarkerDragEnd}
            onMarkerClick={handleMarkerClick}
          />

          <CButton
            label={"Confirm"}
            onClick={() => (
              getLocationPermission(), setLocationIsOpen(false), setIsStep(2)
            )}
          />
        </div> */}

        <div
          style={{
            height: isMobile ? "auto" : "auto",
            width: isMobile ? "auto" : "60vh",
          }}
        >
          {/* <Map
            containerElement={<div style={{ height: "65vh" }} />}
            mapElement={<div style={{ height: "100%" }} />}
            center={currentLocation}
            onMapClick={handleMapClick}
            onMarkerDragEnd={handleMarkerDragEnd}
            onMarkerClick={handleMarkerClick}
          /> */}
          <TextArea
            rows={4}
            placeholder="Address"
            maxLength={200}
            value={address}
            onChange={handleAddressChange}
          />
          {errorAddress && <div style={{ color: "red" }}>{errorAddress}</div>}
          <CButton label={"Confirm"} onClick={isAddressEntered} />
        </div>
      </Modal>
      {/* {isMobile && (
        <Sheet
          isOpen={
            modalMobileLocationIsOpen ||
            modalMobileIsOpen ||
            modalMobileLoginIsOpen
          }
          detent="content-height"
          onClose={() => handleSheetClose()}
        >
          <Sheet.Container>
            <Sheet.Header>
              <div className="sheet-header">
                <h2>Your Sheet Title</h2>
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={handleSheetClose}
                />
              </div>
            </Sheet.Header>

            <Sheet.Content
              style={{
                alignItems: "flex-end",
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                paddingBottom: 20,
              }}
            >
              {modalMobileLocationIsOpen && (
                <div style={{ width: "40vh" }}>
                  <Map
                    containerElement={<div style={{ height: "65vh" }} />}
                    mapElement={<div style={{ height: "100%" }} />}
                    center={currentLocation}
                    onMapClick={handleMapClick}
                    onMarkerDragEnd={handleMarkerDragEnd}
                    onMarkerClick={handleMarkerClick}
                  />

                  <CButton
                    label={"Confirm"}
                    onClick={() => (
                      getLocationPermission(),
                      setLocationIsOpen(false),
                      setMobileLocationIsOpen(false),
                      setIsStep(2)
                    )}
                  />
                </div>
              )}
              {modalMobileIsOpen && (
                <div style={{ width: "60vh", height: "75vh" }}>
                  <Space
                    direction="vertical"
                    size={5}
                    style={{ marginBottom: 5 }}
                  >
                    {["Weekly", "Every 2 Weeks", "One Time"].map((e, index) => (
                      <Card
                        title={e}
                        onClick={() => setIsRadio(index)}
                        size="20px"
                        extra={<Radio checked={index === isRadio} />}
                        className="modalCard"
                        style={{
                          width: "100%",
                          height: 120,
                          marginBottom: 10,
                          borderColor:
                            index !== isRadio ? "#000000" : "#00c3ff",
                          backgroundColor:
                            index !== isRadio
                              ? "#ffffff"
                              : "rgba(0,195,255,0.2)",
                        }}
                      >
                        <ul style={{ marginTop: -10, padding: 0 }}>
                          <li>
                            The same professional will join you every week.
                          </li>
                          <li>
                            The same professional will join you every week.
                          </li>
                        </ul>
                      </Card>
                    ))}
                  </Space>
                  <CButton
                    label={"Select"}
                    onClick={() => (setMobileIsOpen(false), setIsOpen(false))}
                  />
                </div>
              )}

              {modalMobileLoginIsOpen && (
                <div style={{ height: "8vh", width: "100%" }}>
                  {renderGoogleLogin(handleClocl)}
                </div>
              )}
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop />
        </Sheet>
      )} */}
    </div>
  );
};

export default Home;
